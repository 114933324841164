@import "mixins";

$primary-color: #0DB9D8;
$secondary-color: #000000;
$accent-color: #FFE49A;
$warn-color: #DB5656;
$white: #ffffff;
$black: #000000;
$default-color: #E8ECEF;
$error-color: $warn-color;
$success-color: #00534F;
$warning-color: #ffa335;
$info-color: #0DB9D8;
$font-family: 'Inter';
$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$body-background-color: #FFFFFF;
$body-color: #212B36;
$text-light-1: #ABB6BE;
$text-light-2: #6D6D6D;
// side menu
$side-bar-holder-width: 58;
$side-bar-holder-bg-color: #F3FBFD;
$side-menu-active-link-bg-color: #6EE7FF;
; // #BDE8CA;
$side-menu-hover-link-bg-color: #0DB9D8;
; //#BDE8CA;
$side-menu-active-link-color: #FFFFFF;
$side-menu-hover-link-color: #FFFFFF;
$side-menu-link-color: #288adc;
$side-menu-link-font-weight: $font-weight-normal;
$side-bar-toggle-icon-color: #FFFFFF;

//header
$header-height: 42; //using
$header-bg-color: #FFFFFF; //using
$header-border-bottom: 1px solid #DFDFDF; //using

//branding
$branding-holder-height: 46; //using
// PAGE CONTENT
$page-content-bg-color: #F7F9FC; //using
// Radio Button
$radio-button-height: 24px;
$radio-button-inner-circle-height: 12px;
$radio-button-inner-circle-width: 12px;
$radio-button-font-size: 16px;
$radio-button-font-weight: $font-weight-normal;
$radio-button-font-family: $font-family;
$radio-button-border: 2px solid #8f8f8f;
$radio-button-active-border-color: #0DB9D8;
//File picker
$file-picker-border: 1px dashed #0DB9D8; //using
// Helper Text Variables
$helper-text-font-size: 13px; //using
// Form controls Variables
$form-control-font-family: $font-family; //using
$font-control-margin-bottom: 18px; //using
// Input Variables
$input-background-color: #FFFFFF;
$input-border-color: #E7E7E7;
$input-select-border-color: #7EB2F6;
$input-error-border-color: $warn-color;
$input-error-color: $warn-color;
$input-border: 1px solid $input-border-color;
$input-border-radius: 8px;
$input-padding: 3px 12px;
$input-font-size: 14px;
$input-line-height: 28px;
$input-line-sm-height: 18px;
$input-line-md-height: 28px;
$input-color: $body-color;
$input-placeholder-color: #C6CACF;
$input-font-weight: $font-weight-normal;
$input-width: 200px;
// Label Variables
$label-color: #192938;
$label-font-weight: $font-weight-medium;
$label-tool-tip-bg-color: #B8B8B8;
$label-error-color: $warn-color;
//radiobutton variables
$radio-button-base-color: #0DB9D8;
$radio-button-font-size: 16px;
$radio-button-size: 20px;
$radio-button-border-radius: 4px;
$radio-button-border: 1px solid #DFDFDF;
$radio-button-background-color: #FCFCFC;
$radio-button-error-border-color: $warn-color;
$radio-button-error-color: $warn-color;
$radio-button-disabled-opacity: 0.3;
//checkbox variables
$checkbox-base-color: #0DB9D8;
$checkbox-font-size: 16px;
$checkbox-size: 20px;
$checkbox-border-radius: 4px;
$checkbox-border: 1px solid #DFDFDF;
$checkbox-background-color: #FFFFFF;
$checkbox-error-border-color: $warn-color;
$checkbox-error-color: $warn-color;
$checkbox-disabled-opacity: 0.7;
$checkbox-accent-color: #07213C;
// Select
$select-line-height: 34px; //using
$select-placeholder-color: #AFBAC0; //using
$select-border-color: #DFDFDF; //using
$select-border: 1px solid $select-border-color; //using
$select-error-color: $warn-color; //using
$select-error-border-color: $warn-color; //using
// Button Variables
$button-disabled-opacity: 0.5; //using
$button-font-weight: $font-weight-medium; //using
$button-padding: 10px 20px; //using
$button-height: 36px; //using
$button-font-size: 14px; //using
$button-spinner-border-width: 2px; //using
$button-spinner-height: 20px; //using
$button-spinner-width: 20px; //using
$button-primary-font-color: #202937; //using
$button-secondary-base-color: #183258; //using
$button-outlined-bg-color: #ffffff; //using
$button-default-base-color: #000; //using
$button-error-base-color: $error-color; //using
$button-sm-padding: 7px 14px; //using
$button-sm-height: 32px; //using
$button-sm-font-size: 14px; //using
$default-input-background-color: #FAFAFA; //using
$download-csv-button-background-color: #202937; //using
$download-csv-button-font-color: #FFFFFF; //using
$upload-timesheet-button-background-color: #FFFCF5; //using
$upload-timesheet-button-font-color: #866103; //using
$upload-timesheet-button-hover-background-color: #FDF7E7FF; //using

