@import "src/assets/styles/common";

.ts-calendar-wrapper {
  $header-line-color: #FDFDFD;
  $header-color: #E1EBEA;
  $background-color: #FFFFFF;
  $line-color: #E3E3E3;
  $dark-mode: true;
  flex: 1;

  .ts-calendar-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .current-month-year-title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 40px;
  }

  .ts-calendar-weeks {
    display: flex;
    flex: 1;
    flex-direction: row;
    //border-bottom: 1px solid $line-color;
  }

  .ts-week-name-item {
    flex: 1;
    font-size: 1rem;
    text-transform: uppercase;
    color: black;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    background-color: #F5FDFF;
    // border-right: 1px solid $header-line-color;
    border-bottom: 1px solid var(--components-divider, rgba(145, 158, 171, 0.24));
    border-top: 1px solid var(--components-divider, rgba(145, 158, 171, 0.24));

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    &.ts-week-day-0 {
      //background-color: colorLightness($header-color, 10);
    }
  }

  .ts-calendar-dates {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: calc(100% / 6);
    min-height: 100px;
  }

  .ts-day-item-wrapper {
    border-right: 1px solid $line-color;
    border-bottom: 1px solid $line-color;
    flex: 1;
    display: flex;
    width: calc(100% / 7);

    &:first-child {
      //border-top: 1px solid #AAAAAA;
      border-left: 1px solid $line-color;
    }

    .ts-day-item {
      flex: 1;
      color: getTextColorForBackground($header-color);
      padding: 5px;
      //font-weight: bold;
      overflow: hidden;
      background-color: #FAFAFA;

      &.is_selected {
        background-color: $primary-color !important;
        color: getTextColorForBackground($primary-color);
      }

      &.is_disabled {
        //background-color: colorDarkness($background-color, 5) !important;
        color: colorDarkness($background-color, 15);
        background-color: #FAFAFA;
        opacity: 1;
        cursor: not-allowed;
      }


      .ts-day-text {
        display: flex;
        justify-content: right;
        //float: right;
        //text-align: right;
        font-size: 1rem;
        //padding: 5px;
      }

      &.is_today {
        position: relative;
        //border: 3px solid green;
        border: 3px solid var(--Vars-Colors-Secondary, #192938);

        &:before {
          content: '';
        //  background-color: #6EDAEE;
          position: absolute;
          top: 0;
          left: 0;
          height: 3px;
          width: 100%;
          border-radius: 6px;
          opacity: 1;
        }

        //border-radius: 50%;
        //color: getTextColorForBackground($primary-color);
      }
    }

    &:hover {
      .ts-day-item {
        background-color: colorLightness($background-color, 5);
        @if $dark-mode {
          background-color: colorLightness($header-color, 5);
        }
      }
    }

    &.ts-date-not-available {
      //border-color: $background-color;
      //background-color: colorDarkness($background-color, 20);
    }

    &.ts-week-day-0 {
      .ts-day-item {
        //background-color: colorLightness($header-color, 10);
      }
    }
  }
}
