.edit-agency-additional-rate-screen {
  .rate-list-item{
    margin-bottom: 15px;
  }
  .edit-agency-additional-rate-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
  }

  .agency-additional-rate-component {
    .agency-additional-rate-wrapper {
      padding: 12px 24px;
      height: calc(100vh - 100px);
      overflow: auto;
      .input-component {
        margin-bottom: 0px !important;
      }
    }
  }
}