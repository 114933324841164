.facility-list-component {
  .table-component {
    .t-table {
      .t-thead {
        .th {
          color: #8C949B;
        }
      }

      .t-body {
        .t-tr {
          &:hover {
            .t-td {
              background-color: #F1FDFF;
            }
          }
        }
      }
    }
  }

  .fms-length {
    color: #5F5F61;
    font-weight: 600;
  }

  .facility-details-wrapper {
    display: flex;
    align-items: center;

    .facility-name-tower-wrapper {
      // margin-left: 10px;

      .facility-name {
        //color: #183258;
        //font-weight: 600;
      }

      .towers-count {
        color: #5F5F61;
        margin-top: 5px;
      }

      .ellipses-for-table-data {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .list-screen-content {
    .t-table {
      height: calc(100vh - 240px) !important;
    }
  }
}

.facility-table-component{
  .ant-table-body{
    min-height: 60vh !important;
    max-height: 60vh !important;
  }
}