.approved-shift-view-details {
  .MuiTabs-root {
    width: 35rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 12px;
      font-weight: 700;
    }
  }
  .approved-header {
    //background: linear-gradient(180deg, #FFF 23.96%, #FFF4EA 100%);
  }


  .shift-details__timesheet {
    display: flex;
    justify-content: right;
  }

  .ant-table-body{
    min-height: 50vh !important;
    max-height: 50vh !important;
  }
}

.document-list-wrapper {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
}

.HCPName {
  color: var(--text-light-primary, #212B36);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.HCPType {
  color: var(--facility-vars-grays-caded-gray, #9EA2A8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
