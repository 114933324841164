.approval-shift-card {
  padding: 20px 20px 2px 20px;
  border-radius: 12px;
  border: 1px solid #BEEDF6;
  //background: linear-gradient(0deg, #F5FDFF 0%, #F5FDFF 100%), #BEEDF6;
  margin-bottom: 15px;
}

.minor-text-font {
  font-size: 11px;
}

.visit-patient-by-text {
  color: var(--Vars-Colors-Menu-Red, #202937);
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
}

.hcp-name-for-approval {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.hcp-other-details-for-approval-text {
  color: #929292;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.approves-popup-open-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
