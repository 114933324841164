.link-component {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.002em;

  &:not(&:has(button)) {
    text-decoration: underline;
    color: #192938;
  }

  &:hover {
    color: #192938;
    cursor: pointer;
    text-decoration: none !important;
  }
}
