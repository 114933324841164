@import "src/assets/styles/common";

.password-component {
  position: relative;

  .input-suffix-password-toggler {
    position: absolute;
    top: 37px;
    right: 10px;
    color: var(--vars-primary-gunmetal, #192938);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.688%;

    &:hover {
      cursor: pointer;
    }

  }
}
