.scheduler-legend-component {
  min-width: 605px;

  .scheduler-legend-header {
    border-radius: 16px 0 0 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 26px;
    border-bottom: 1px solid #E7E7E7;
    color: var(--Facility-Vars-Primary-Gunmetal, #192938);
    font-size: 16px;
    font-weight: 600;
  }

  .scheduler-legend-body {
    .legend-card {
      border-radius: 8px;
      border: 1px solid #E7E7E7;
      padding: 8px;
    }

    .color-box {
      width: 30px;
      height: 30px;
      border-radius: 6px;
    }

    .green {
      background-color: #7FD4B0;
    }

    .blue {
      background-color: #669FF3;
    }

    .purple {
      background-color: #C272F3;
    }

    .rt-openshift-color {
      background-color: #FDE7A3;
    }

    .rt-contract-travel-shift-color {
      background-color: #C0E5FF;
    }

    .rt-hospice-shift-color {
      background-color: #FFBE90;
    }

    .ss-pending-status-color {
      background-color: #B3E1FD;
    }

    .ss-approved-status-color {
      background-color: #FFF3E6;
    }

    .ss-completed-status-color {
      background-color: #CBF3D1;
    }

    .ss-confirmed-status-color {
      background-color: #DFDFDF;
    }

    .ss-cancelled-status-color {
      background-color: #FFCACA;
    }
  }
}


.legend-header-card-text {
  color: var(--Text-Light-Primary, #212B36);
  font-size: 14px;
  font-weight: 400;
}