.search-checkbox-component{
  height: 100%;
  width: 100%;
  .placeholder-custom{
    color: #C6CACF;
  }

  .disabled{
    cursor: default;
  }

  .selected-values {
    display: flex;
    align-items: center;
    height: 20px;
    overflow: hidden;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}

