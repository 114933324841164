.details-screen {
  .details-screen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .details-screen-title-with-status {
      .details-screen-title-wrapper {
        display: flex;
        align-items: baseline;
        column-gap: 15px;

        .details-screen-title {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    .details-screen-title-with-image-wrapper {
      display: flex;
      align-items: center;

      .details-screen-image {

      }

      .details-screen-title-wrapper {
        margin-left: 10px;

        .details-screen-title {
          font-weight: 600;
          font-size: 18px;
        }

        .details-screen-description {
          margin-top: 5px;
          font-weight: 400;
          font-size: 14px;
          word-break: break-all;
        }
      }
    }

    .details-screen-options {
      display: flex;
      column-gap: 10px;
    }
  }

  .details-screen-body {
    margin-top: 20px;
  }
}
