.edit-facility-component {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .edit-facility-header {
   // background: linear-gradient(180deg, #E0FAFF 23.96%, #FFF 100%);
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form-holder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);

    .add-form-holder {
      flex-grow: 1;
      overflow-y: auto;
      max-height: calc(100vh - 110px); /* Set the max height for the form content */
      flex-shrink: 0;
    }
  }


  .edit-component-content {
    padding-top: 16px;
    padding-right: 24px;
    padding-left: 24px;
    border-top: 1px solid #E4E7EA;
  }


  .MuiFormControl-root {
    margin: 0 !important;

    .MuiInputBase-root {
      outline: none !important;
      border-radius: 8px !important;
      border: none !important;
      //  background: #FAFAFA;

      &:active,
      &:focus {
        padding: 11px 14px;
        border: 1px solid #212B36;
      }
    }
  }

  .facility-edit-save-button {
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
   // background: linear-gradient(180deg, #FFF 23.96%, #E0FAFF 100%);
  }
}