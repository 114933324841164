@import "src/assets/styles/common";

.confirmation-modal {
  .modal-content-wrapper {
    max-width: 530px;
  }

  .confirmation-dialog-container {

    .confirmation-dialog-image-container {
      display: flex;
      justify-content: center;
      margin-bottom: 11px;

      .confirmation-dialog-image {
        // height: 40px;
        width: auto;

        img {
          height: 100%;
          width: auto;
        }
      }

    }

    .confirmation-dialog-content-wrapper {
      padding: 30px 0 15px 0;

      .confirmation-dialog-title {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 9px;
      }

      .confirmation-dialog-sub-title {
        color: #6D6D6D;
        text-align: center;
        font-weight: 600;
      }

      .confirmation-dialog-sub-description {
        margin-left: 96px;
        margin-right: 96px;
        text-align: center;
        font-weight: 400;
        width: 302px;
        font-size: 16px;
        color: #7D7D7D;
      }
    }
  }

  .confirmation-dialog-yes-btn {
    box-shadow: none;
    // background: black !important;
    color: #202937 !important;
  }

  .confirmation-dialog-no-btn {
    border: none;
    background: black !important;
    font-weight: 700;
    cursor: pointer;
    color: white !important;
  }
}

.activate-linear {
  // background: linear-gradient(178deg, #D3F8FB 0.2%, #FFF 11.39%);
  //background: linear-gradient(180deg, rgba(156, 254, 219, 0.78) 23.96%, rgba(247, 249, 252, 0.00) 100%);
}

.deactivate-linear {
  //background: linear-gradient(178deg, rgba(254, 156, 156, 0.37) 7.8%, #FFF 9.39%);
  // background: linear-gradient(178deg, rgba(254, 156, 156, 0.37) 0%, rgba(254, 156, 156, 0.37) 7.8%, #FFF 9.39%);
  //background: linear-gradient(180deg, rgba(247, 249, 252, 0.00) 23.96%, rgba(254, 156, 156, 0.37) 100%);
  //background: linear-gradient(180deg, rgba(254, 156, 156, 0.37) 23.96%, rgba(247, 249, 252, 0.00) 100%);
}