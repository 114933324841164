.auth-layout {
  width: 100vw;
  height: 100vh;
  background: none;
  display: flex;
  .mask-icon-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .branding-component {
    width: 50vw;
    height: 100vh;
    //position: absolute;
    //left: 0;
    //right: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("./../../assets/images/banner-image.png") no-repeat left;
    background-size: 50vw 100vh;

    .logo-wrapper {
      height: 48px;
      width: 456px;
      position: absolute;
      top: 30px;
      left: 15px;
    }
  }

  .auth-wrapper {
    height: 100vh;
    width: 50vw;
    background: #FFFFFF;
    //position: absolute;
    //left: unset;
    //right: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-form {
      //@include display-center();
      flex-direction: column;
    }
  }
}

//.auth-layout {
//
//
//  .mask-icon-container {
//
//  }
//
//  .branding-component {
//
//    .logo-wrapper {
//
//
//      img {
//        width: 100%;
//        height: 100%;
//      }
//
//
//      @include lg() {
//        top: 49px;
//        left: 36px;
//      }
//    }
//
//    //@include md() {
//    //  background: url("./../../assets/images/banner-image.png") no-repeat left;
//    //  background-size: 50vw 100vh;
//    //}
//    //
//    //@include lg() {
//    //  background: url("./../../assets/images/banner-image.png") no-repeat left;
//    //  background-size: 50vw 100vh;
//    //}
//
//  }
//
//  .auth-wrapper {
//    //width: 50vw;
//    height: 100vh;
//    background: #fff;
//    position: absolute;
//    left: unset;
//    right: 150px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    // padding: 0 60px;
//
//    //@include md() {
//    //  width: 480px;
//    //}
//
//
//    .auth-form {
//      @include display-center();
//      flex-direction: column;
//
//      //.auth-form-title {
//      //  font-size: 24px;
//      //  font-weight: $font-weight-bold;
//      //  margin: 10px 0 30px 0;
//      //  text-align: left;
//      //}
//      //
//      //.auth-form-sub-title {
//      //  //text-align: center;
//      //  margin: 30px 0;
//      //  color: $black;
//      //  opacity: 0.5;
//      //}
//    }
//
//  }
//}