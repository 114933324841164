$success-bg-color: #ECF8EF;
$success-color: #1FAE6A;
$info-bg-color: #E0F6FF;
$info-color: #1FAE6A;
$warning-bg-color: #F5F7F8;
$warning-color: #9D9D9D;
$primary-bg-color: #EBF1FD;
$primary-color: #0DB9D8;
$default-bg-color: #E0F6FF;
$default-color: #636363;
$danger-bg-color: #FDEBEB;
$danger-color: #CE0202;

.tag-component {
  display: inline-flex;
  align-items: center;
  padding: 3px 15px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 500;
  //margin: 0 0 5px 5px;

  &.success {
    background-color: $success-bg-color;
    color: $success-color;
  }

  &.info {
    background-color: $info-bg-color;
    color: $info-color;
  }

  &.warning {
    background-color: $warning-bg-color;
    color: $warning-color;
  }

  &.primary {
    background-color: $primary-bg-color;
    color: $primary-color;
  }

  &.default {
    background-color: $default-bg-color;
    color: $default-color;
  }

  &.danger {
    background-color: $danger-bg-color;
    color: $danger-color;
  }

  .indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;

    &.success {
      background-color: $success-color;
    }

    &.info {
      background-color: $info-color;
    }

    &.warning {
      background-color: $warning-color;
    }

    &.primary {
      background-color: $primary-color;
    }

    &.default {
      background-color: $default-color;
    }

    &.danger {
      background-color: $danger-color;
    }

  }

  .label {
    line-height: 18px;
  }

}
