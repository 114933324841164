.edit-admin-component {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .edit-admin-header {
    //padding-top: 16px;
    //padding-right: 24px;
    //padding-left: 24px;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form-holder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .add-form-holder {
      flex-grow: 1;
      overflow-y: auto;
      min-height: calc(100vh - 110px);
      max-height: calc(100vh - 110px); /* Set the max height for the form content */
      flex-shrink: 0;
    }
  }

  .edit-component-content {
    padding-top: 16px;
    padding-right: 24px;
    padding-left: 24px;
    border-top: 1px solid #E4E7EA;
  }

}