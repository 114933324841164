.day-month-open-shift-hcp-view-details {
  //min-width: 1100px;
  min-height: 640px;
  width: 100%;
  .approved-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .non-header-container {
    margin-right: 20px;
    margin-left: 20px;
  }

  .shift-details__timesheet {
    display: flex;
    justify-content: right;
  }
}

.document-list-wrapper {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
}