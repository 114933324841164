@import "src/assets/styles/variables";

.shift-requirement-screen {

  .shift-list-component{
    .ant-table-body{
      min-height: 62vh !important;
      max-height:62vh !important;
    }
  }


  .MuiTabs-root {
    width: 50rem;
    min-height: 36px;
    max-height: 36px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 2rem;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 16.66%;
      font-size: 14px;
      font-weight: 600;
    }
  }

  //.MuiButtonBase-root{
  //  max-width: 8rem;
  //}
  .MuiLinearProgress-bar1Determinate {
    background-color: #0DB9D8;
  }
}

.pending {
  padding: 2px 8px;
  color: #DE6944;
  align-items: center;
  border-radius: 115px;
  border: 1px solid rgba(222, 105, 68, 0.00);
  background: var(--facility-vars-status-orange-old-race, #FFF4E5);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.uploaded {
  color: var(--facility-vars-status-green-pigment-green, #1FAE6A);
  padding: 2px 8px;
  align-items: center;
  border-radius: 115px;
  border: 1px solid rgba(222, 105, 68, 0.00);
  background: var(--facility-vars-status-orange-old-race, #ECF8EF);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.shift-management-list-input-box {
  .input-component {
    position: relative;
    margin-bottom: 12px !important;
    width: 250px;
  }
}

.requirement-status-tag{
  font-family: $font-family;
  padding-right: 15px;
  //width: 130px;
  //height: 30px;
  .requirement-status{
    width: inherit;
    height: 100%;
    text-align: center;
    margin-bottom: 0px;
  }
}
