.details-header-component {
  height: 47px;
  border-bottom: 1px solid #E4E7EA;
  background-color: #FFFFFF;

  .details-header-back-button-wrapper {
    display: flex;
   // justify-content: center;
    align-items: center;
  }

  .details-header-name-wrapper {
    display: flex;
   // justify-content: center;
    align-items: center;
    color: #212B36;
    font-size: 18px;
    font-weight: 500;
  }
  .button-component.primary.outlined{
    background:none;
    color: #202937;
  }
}
