$rightSvg: "../../../assets/linear-gradient-asset/add-layout-right.svg";
$leftSvg: "../../../assets/linear-gradient-asset/add-layout-left.svg";
$stepperSvg: "../../../assets/linear-gradient-asset/stepper-component.svg";
.add-user-details-component {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: url('#{$rightSvg}') no-repeat fixed top right, url('#{$rightSvg}') no-repeat fixed top left;

  .add-user-sidebar-steper {
    width: 25vw;
  }

  .add-user-formik-wrapper {
    width: 71vw;
  }

  .add-user-form-action-element {
    height: 8.7vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-top: 1px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 4px 50.9px 0px rgba(187, 236, 245, 0.50);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .back-button {
      border-radius: 6px;
      border: 1px solid rgba(51, 192, 201, 0.21);
      background: var(--asure-light-cyan, #F1FBFD);
      color: #202937;
    }

  }
}