$rightSvg: "../../../../assets/linear-gradient-asset/add-layout-right.svg";
$leftSvg: "../../../../assets/linear-gradient-asset/add-layout-left.svg";
$stepperSvg: "../../../../assets/linear-gradient-asset/stepper-component.svg";

.add-facility-details-component {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: url('#{$rightSvg}') no-repeat fixed top right, url('#{$rightSvg}') no-repeat fixed top left;

  .add-facility-sidebar-steper {
    width: 25vw;
  }

  .add-facility-formik-wrapper {
    width: 71vw;
  }

  .add-component-content {
    height: calc(100vh - 20px);

    .add-form-wrapper-box {
      height: 87vh;
      overflow-y: auto;
    }
  }

  .add-facility-form-action-element {
    height: 8.7vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-top: 1px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 4px 50.9px 0px rgba(187, 236, 245, 0.50);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .back-button {
      border-radius: 6px;
      border: 1px solid rgba(51, 192, 201, 0.21);
      background: var(--asure-light-cyan, #F1FBFD);
      color: black;
    }
  }
}

.add-facility-sidebar-steper-wrapper {
  border: 1px solid #ECECEC;
  background: url('#{$stepperSvg}') bottom / 100% auto no-repeat, linear-gradient(180deg, #F7F9FC 23.96%, #E0FAFF 100%);
  // background: url('#{$rightSvg}') no-repeat fixed top right, url('#{$rightSvg}') no-repeat fixed top left;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wing-list-wrapper {
  max-height: 8rem;
  overflow-y: auto;
  padding: 10px;

  .wing-details-holder {
    padding: 10px 22px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    margin-bottom: 12px;

    .facility-add-wing-text {

    }
  }
}

.ant-table-body{
  min-height: 45vh !important;
  max-height: 45vh !important;
}