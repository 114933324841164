.open-shift-details-creation-month-day-view-component {
  min-width: 1300px;
  max-width: 1300px;
  min-height: 640px;
  overflow: hidden;

  .open-shift-details-creation-month-day-view-header {
    padding: 14px 24px;
    border-bottom: 1px solid #E4E7EA;
    color: var(--facility-vars-primary-gunmetal, #192938);
    /* Font/16px - 600 */
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .open-requirement-shift-details {
    min-width: 28%;
    max-width: 28%;
    height: 79vh;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid #BEEDF6;
    margin-top: 2px;
    background: url("../../../../assets/icons/side-menu/open-shift-details.svg") bottom / 100% auto no-repeat, linear-gradient(180deg, #F5FDFF 0%, #EEF9FC 100%);
  }

  .open-requirement-hcp-assigning-wrapper {
    max-width: 72%;
  }

  .shift-requirement-day-week-details-wrapper {
    padding-top: 25px;
    padding-left: 12px;
    padding-right: 12px;

    .shift-requirement-week-day-details-card {
      border-radius: 8px;
      border: 1px solid #BEEDF6;
      background: #FFF;
    }
  }

  .MuiTabs-root {
    width: 30rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 2rem;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.scheduler-open-shift-list-component{
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background-color: #ffffff !important; /* Ensure table cells are white */
  }
  .ant-table-body{
    min-height: 55vh !important;
    max-height: 55vh !important;
    border-right: 1px solid #DFDFDF;
  }
}