$rightSvg: "../../../../assets/linear-gradient-asset/add-layout-right.svg";
$leftSvg: "../../../../assets/linear-gradient-asset/add-layout-left.svg";
$stepperSvg: "../../../../assets/linear-gradient-asset/stepper-component.svg";
.container {
  display: flex;
  align-items: center;
  width: 100%; /* Adjust the height as needed */
  .child {
    text-align: center;
  }

  .child1 {
    flex: 1;
    text-align: center;
  }
}


.document-upload-box {
  display: flex;
  min-height: 62px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  //border-bottom: 1px solid #E4E7EA;
  border-radius: 8px;
  border: 1px solid var(--Text-Blue, #E7E7E7);
}

.file-preview-delete-wrapper {
  display: flex;
  align-items: center;

  .delete-box {
    margin-right: 5px;
    border: 1px solid #DB5656;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--facility-vars-status-red-misty-rose, #FFF4F4);
    cursor: pointer;
    margin-left: 4px;
  }

  .preview-box {
    margin-right: 5px;
    border: 1px solid #0DB9D8;
    display: flex;
    padding: 7px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--facility-vars-primary-light-cyan, #E1FDFF);
    cursor: pointer;
    margin-left: 4px;
  }

  .file-text {
    color: var(--facility-vars-grays-davys-grey, #4E535F);
    font-size: 14px;
    font-weight: 500;
    text-decoration-line: underline;
    margin-right: 10px;
  }
}

.upload-button-holder {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-hcp-details-component {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: url('#{$rightSvg}') no-repeat fixed top right, url('#{$rightSvg}') no-repeat fixed top left;

  .add-hcp-sidebar-steper {
    width: 25vw;
  }

  .add-hcp-formik-wrapper {
    width: 71vw;
  }

  .add-hcp-form-action-element {
    height: 8.7vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-top: 1px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 4px 50.9px 0px rgba(187, 236, 245, 0.50);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .back-button {
      border-radius: 6px;
      border: 1px solid rgba(51, 192, 201, 0.21);
      background: var(--asure-light-cyan, #F1FBFD);
      color: #202937;
    }
  }
}