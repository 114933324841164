.details-screen {
  .details-screen-title-with-image-wrapper {
    background-color: #F5FDFF;
    border-radius: 8px;
    margin: 10px;
    border: 1px solid #BEEDF6;
  }

  .details-screen-title {
    font-size: 16px;
    font-weight: 600;
  }

  .list-screen {
    .t-table-wrapper .t-table:not(.t-table-wrapper .t-table.auto-height) {
      //height: auto;
      min-height: auto;
    }

    .t-table-wrapper .t-table .table-no-data-wrapper {
      min-height: 0 !important;
      height: auto !important;
    }

    .facility-table-list {
      border: 1px solid #DFDFDF;
      border-radius: 10px;
    }
  }
}

.details-screen-options {
  display: flex;
  justify-content: right;

  .button-container {
    display: flex;
  }
}

.css-1ujnqem-MuiTabs-root {
  min-height: 200px;
}

.facility-details-component-wrapper {
  .MuiTabs-root {
    width: 50rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 100px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 20%;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .shift-timings-wrapper {
    border-radius: 8px;
    background: #FFF;
    margin-top: 10px;
    //box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      background-color: #ffffff !important; /* Ensure table cells are white */
    }
    .ant-table-body{
      min-height: 60vh !important;
      max-height: 60vh !important;
      border-right: 0.5px solid #DFDFDF;
    }
  }
}