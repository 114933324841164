.accordion-component {
  width: 100%;
  border: 1px solid #B8B8B8;
  border-radius: 5px;
  margin-bottom: 20px;
}

.accordion-item {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  background-color: #EEF1F5;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
}

.accordion-title {
  text-transform: capitalize;
  padding: 1rem;
}

.accordion-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 20px;

  .accordion-action-buttons {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .accordion-icon-action {
      padding: 1rem;
    }
  }
  .accordion-arrows{
    padding: 11px;
  }
}

.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #FFFFFF;
  border-radius: 0 0 5px 5px;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}