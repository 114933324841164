.agency-list-component {
  .fms-length {
    color: #5F5F61;
    font-weight: 600;
  }

  .agency-details-wrapper {
    display: flex;
    align-items: center;

    .agency-name-tower-wrapper {
      // margin-left: 10px;

      .agency-name {
        color: #183258;
        font-weight: 600;
      }

      .towers-count {
        color: #5F5F61;
        margin-top: 5px;
      }

      .ellipses-for-table-data {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .list-screen-content {
    .t-table {
      height: calc(100vh - 240px) !important;
    }
  }
}

.agency-table-component{
  .ant-table-body{
    min-height: 63vh !important;
    max-height: 63vh !important;
  }
}