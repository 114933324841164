@import "src/assets/styles/variables";

.t-table-wrapper {
  width: 100%;
  height: 100%;
  //overflow: auto;

  .t-table {
    width: 100%;
    height: 100%;
    border-radius: 0 0 10px 10px;
    position: relative;

    &:not(&.auto-height) {
      min-height: 400px;
    }

    .autoComplete-component-wrapper, .input-component {
      margin-bottom: 0 !important;
    }

    .MuiFormHelperText-root.Mui-error {
      display: none !important;
    }

    &.sticky {
      .t-body {
        top: -2px !important;
      }
    }

    .t-tr {
      &.t-tr-expand {
        .t-td {
          position: sticky;
          justify-content: flex-start;
        }
      }
    }

    .t-thead {
      border-radius: 12px;

      .t-th {
        min-height: 51px;
        padding: 11px;
        //background-color: #FAFAFA;
        border-bottom: 1px solid #F1F1F1;
        color: #192938;
        font-weight: 500;
        font-size: 12px;

        &:first-child {
          border-top-left-radius: 12px;
          // border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 12px;
          //   border-bottom-right-radius: 10px;
        }

        &.sortable {
          position: relative;
          cursor: pointer;

          &::after {
            content: url("./../../../assets/icons/table/sort.svg");
            color: $body-color;
            opacity: 0.2;
            margin-left: 8px;
            width: 20px;
            position: relative;
            top: 2px;
          }

          &.sort-asc::after {
            opacity: 1;
            content: url("./../../../assets/icons/table/sort_asc.svg");
          }

          &.sort-desc::after {
            opacity: 1;
            content: url("./../../../assets/icons/table/sort_desc.svg");
          }
        }
      }
    }


    .t-body {
      .t-td {
        height: 52px;
        padding: 6px;
        color: #4E535F;
        font-weight: 400;
        font-size: 14px;
      }

      .t-tr:has(.selected-row) {
        .t-td {
          background-color: #FAFAFA;
        }
      }

      .t-tr-clickable {
        cursor: pointer;

        .t-cell {
          & .table-cell-heading {
            color: #183258;
            font-weight: 500;
          }
        }

        &:hover {
          .t-td {
            background-color: #FAFAFA;
          }
        }
      }
    }

    &.bordered {
      .t-cell {
        border-right: 1px solid #F3F3F3;
      }
    }

    .t-th, .t-td {

      &.t-th {
        //   border-bottom: none;
      }

      &:first-child {
        padding-left: 20px;
      }

      // border-bottom: 1px solid #DFDFDF;
      background-color: #fff;
      overflow: hidden;

      display: flex !important;
      justify-content: center;
      align-content: flex-start;
      align-items: center;
      align-self: center;

      &.t-cell-align-left {
        justify-content: flex-start;
      }

      &.t-cell-align-center {
        justify-content: center;
      }

      &.t-cell-align-right {
        justify-content: flex-end;
      }
    }

    .table-no-data-wrapper {
      min-height: 300px;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

    }

    .data-loading-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      pointer-events: none;

      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

    }

    .t-tr {
      &:last-child {
        .t-td {
          // padding-right: 20px;
          border-bottom: none !important;
        }
      }
    }
  }

}
