.edit-drawer {
  .MuiDrawer-paper {
    border-radius: 24px 0 0 24px;
   // padding: 23px 25px;

    .drawer-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .drawer-header {
      }

      .drawer-body {
        height: 100%;

        .form-wrapper {
          height: 100%;

          .t-form {
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .form-action {
              display: flex;
              justify-content: center;

              .button-component {
                background: #192938;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
