.bulk-upload-content {
  //   border: 1px solid red;
  padding-left: 10.8572rem;
  padding-right: 10.8572rem;
  padding-top: 1.678rem;

  .bulk-upload-tab-wrapper {
    //margin-bottom: 1.678rem;
    display: flex;
    justify-content: space-between;
  }

  .bulk-upload-table-wrapper {
    .bulk-upload-list-screen {
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 4px;

      .bulk-upload-list-screen-content {
        .t-table {
          height: calc(100vh - 260px);
        }

        .MuiTablePagination-root {
          // border-top: 1px solid #ddd;
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }

  .MuiTabs-root {
    width: 36rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
