.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img {
    max-width: 100%;
    height: auto;
  }
  p {
    margin-top: 16px;
    font-size: 1.5rem;
    color: #333; // Customize the text color as needed
  }
}