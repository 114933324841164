@import "src/assets/styles/common";

.input-component {
  position: relative;
  margin-bottom: $font-control-margin-bottom;
  width: 250px;
  font-family: $font-family;

  input[type="text"], textarea {
    background-color: #FFF;
  }

  &.fullWidth {
    width: 100%;

    .input-field {
      width: 100%;
    }

    .input-field:-webkit-autofill {
      background-color: yellow; /* Add your desired background color here */
    }
  }

  .input-field {
    width: 100%;
    background: #FFF;
    color: $input-color;
    border-radius: $input-border-radius;
    outline: none;
    padding: $input-padding;
    border: $input-border;
    font-size: $input-font-size;
    line-height: $input-line-height;
    font-weight: $input-font-weight;
    font-family: $font-family;
    // box-shadow: 0 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);

    &.small {
      line-height: $input-line-sm-height;
    }

    &.medium {
      line-height: $input-line-md-height;
    }

    &::placeholder {
      //color: $input-placeholder-color;
      //font-size: 14px;
      //font-weight: 600;
      color: #C6CACF !important;
      font-size: 14px;
      font-weight: 400 !important;
    }

    &:disabled {
      background-color: #f2f2f2;
      opacity: 0.7;
      cursor: not-allowed;
      // background: colorDarkness($input-background-color, 10);
    }

    &:hover {
    }

    &:focus, &:active {
      border: 1px solid #182A39;
    }

    &.has-error {
      //color: $input-error-color;
      border: 1px solid $input-error-border-color;
    }

    &.text-transform {
      text-transform: capitalize !important;
    }
  }

  .input-prefix {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .input-suffix {
    position: absolute;
    top: 8px;
    right: 12px;
  }

}


.suffix-padding {
  padding: 3px 40px 3px 12px;
}
