.open-shift-view-details-component {
  display: flex;
  padding: 1rem;

  .data-loading-wrapper {
    //  margin-top:20%;
  }

  .shift-details-wrapper {
    min-width: 30vw;
    max-width: 30vw;
    min-height: 90vh;
    max-height: 90vh;
    overflow: auto;
    padding: 1rem;
    border: 1px solid var(--facility-vars-grays-border-gray, #BEEDF6);
    // background: var(--facility-vars-primary-white, #FFFFFF);
    background: url("../../../../assets/icons/side-menu/open-shift-details.svg") bottom / 100% auto no-repeat, linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    border-radius: 6px;

    .shift-details-header {
      color: var(--facility-vars-primary-gunmetal, #202937);
      font-size: 16px;
      font-weight: 600;
    }

    .shift-details-card-wrapper {
      background-color: #F5FDFF;
      padding: 21px 16px 0px 16px;

      border: 1px solid var(--facility-vars-primary-pacific-cyan, #BEEDF6);
      border-radius: 8px;
    }
  }

  .shift-application-wrapper {
    width: 70vw;
    min-height: 86vh;
    max-height: 86vh;
    // padding: 1rem;
  }

  .MuiTabs-root {
    width: 30rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 15px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .cancel-shift-button {
    box-shadow: none;
    padding: 18px 30px;

  }

  /* Modal Container */
  .conformation-container {
    border: 5px solid red;
    width: 100%;
    height: 100%;
  }
}

.open-shift-list-component{
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background-color: #ffffff !important; /* Ensure table cells are white */
  }
  .ant-table-body{
    min-height: 52vh !important;
    max-height: 52vh !important;
  }
}