.user-management-list-component {
  .MuiTabs-root {
    width: 20rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 50%;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .fms-length {
    color: #5F5F61;
    font-weight: 600;
  }

  .facility-details-wrapper {
    display: flex;
    align-items: center;

    .facility-name-tower-wrapper {
      // margin-left: 10px;

      .facility-name {
        //color: #183258;
        //font-weight: 600;
      }

      .towers-count {
        color: #5F5F61;
        margin-top: 5px;
      }

      .ellipses-for-table-data {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .list-screen-content {
    .t-table {
      height: calc(100vh - 240px) !important;
    }
  }
}
