.date-picker-component-wrapper {
  min-height: 40px;

  .clock-icon {
    position: absolute;
    right: 0;
    display: flex;
    width: 34px;
    align-items: center;
    height: 100%;
    padding-bottom: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  &.full-width {
    width: 100%;
  }

  .time-picker-input {
    input {
      padding-right: 30px;
      box-sizing: border-box;
    }
  }
}

.MuiInputBase-root {
  max-height: 36px;
}
