.bulk-upload-file-component {
  min-width: 1100px;
  min-height: 296px;

  .bulk-upload-shift-text {
    color: var(--Facility-Vars-Primary-Gunmetal, #192938);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .header-bulk-upload {
    padding: 14px 20px 14px;
  }

  .bulk-upload-file-content {

  }

  .file-preview-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 11px 12px;
    border-radius: 8px;
    border: 1px solid #0DB9D8;
    background-color: #F5FDFF;
  }

  .file-name {
    margin-top: 2px;
  }
}
