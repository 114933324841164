.preview-component {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  color: #25AD94;;
  border: 0.5px solid #25AD94;
  background: #DBFFF8;
  .preview-file-name {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .preview-file-name-text {
 //   text-decoration: underline;
  }

  .btn-wrapper {
    display: flex;
    .download-box {
      display: flex;
      padding: 5px;
      justify-content: center;
      align-items: center;
      //border-radius: 6px;
    //  background: var(--New-light-cyan-button, #E7F8FB);
      cursor: pointer;
      margin-right: 4px;
    }

    .delete-box {
      display: flex;
      padding: 5px;
      justify-content: center;
      align-items: center;
      //border-radius: 6px;
      //background: var(--New-light-cyan-button, #E7F8FB);
      cursor: pointer;
      margin-left: 4px;
    }
  }
}