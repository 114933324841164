.hcp-details-component-screen {
  .MuiTabs-root {
    width: 50rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 100px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 20%;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .show-empty-component {
    margin-right: 24rem;
    margin-left: 24rem;
    margin-top: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .show-empty-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .oops-text {
      color: var(--facility-vars-primary-pacific-cyan, #0DB9D8);
      font-size: 24px;
      font-weight: 500;
      margin-top: 20px;
    }

    .info-text {
      margin-top: 20px;
      width: 33rem;
      text-align: center;
      color: #9EA2A8;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }
}


.card-component {

  .card-header {
    border-radius: 8px;
    border: 1px solid #BEEDF6;
    background: #F5FDFF;
    margin: 12px 12px 0;

    .card-title {
      font-size: 16px;
    }

    .button-font-text {
      font-size: 12px;

      svg {
        height: 20px;
      }
    }
  }

  .card-body {
    .no-content {
      margin-bottom: 20px;

      .description-text {
        text-align: center;
      }

      .action-button {
        margin-top: 20px;

        .button-component {
          margin: auto;
          background: #192938;

          svg {
            width: 20px;
          }
        }
      }
    }
  }
}
.HCPName {
  color: var(--text-light-primary, #212B36);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.HCPType {
  color: var(--text-light-primary, #212B36);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.contract-action-text {
  color: var(--text-light-primary, #0DB9D8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &.delete-button {
    color: #EA6F6F;
  }
}

.edit-drawer {
  .edit-contract-wrapper {
    padding-top: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .horizontal-line-wrapper {
      margin-top: 21px;
      margin-bottom: 18px;
    }

    .file-preview-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 11px 12px;
      border-radius: 5px;
      border: 1px solid #E7E7E7;
    }

    .delete-button {
      color: #EA6F6F;;
    }

    .button-component {
      &.save-btn {
        color: #FFF;
        background: #192938;
        margin: auto;
      }
    }
  }
}

.no-content {
  margin-bottom: 20px;

  .description-text {
    text-align: center;
  }

  .action-button {
    margin-top: 20px;

    .button-component {
      margin: auto;
      background: #192938;

      svg {
        width: 20px;
      }
    }
  }
}

.edit-hcp-header {
  //background: linear-gradient(180deg, #E0FAFF 23.96%, #FFF 100%);
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contract-save-button {
  margin-top: 90%;
}

.hcp-education-details-tab-component, .hcp-work-experience-tab-component {
  //max-height: 80vh;
  //overflow: auto;

  .card-component {
    max-height: 80vh;
    overflow: auto;
    .card-body {
      padding-left: 14px;
      padding-right: 14px;

      .card-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        .details-wrapper {
          padding: 15px 18px;
          border-radius: 8px;
          border: 1px solid #E7E7E7;
          background: #FCFCFC;

          .details-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #202937;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;

            .header-action {
              cursor: pointer;
            }
          }

          .horizontal-line-wrapper {
            margin-bottom: 20px;
          }
        }
      }

      .no-data-found {
        height: 241px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #202937;
      }
    }
  }
}

.hcp-shift-list-component{
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background-color: #ffffff !important; /* Ensure table cells are white */
  }
  .ant-table-body{
    min-height: 56vh !important;
    max-height: 56vh !important;
  }
}