.search-component {
  .medium {
    height: 36px;
  }

  .search-close-icon {
    background-color: #fff;
    padding-left:12px;
    :hover {
      cursor: pointer;
      //height: 36px;
    }
  }
}
